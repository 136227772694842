import { IS_DEVELOPMENT, IS_PREPRODUCTION, IS_STAGING } from "shared-utils"

export const LIMIT_ALL_TRANSACTION_VALUE = "ORDER-LIMIT_ALL_TRANSACTION_VALUE"
export const LIMIT_PD_TRANSACTION_VALUE = "ORDER-LIMIT_PD_TRANSACTION_VALUE"
export const PAY_SIMULATION_BUTTON = "PAYMENT-PAY_SIMULATION_BUTTON"
export const REMOVE_REGION_FORM_ADDRESS = "LOGISTIC-REMOVE_REGION_FORM_ADDRESS"
export const VALIDATE_LOGISTIC_KILL_SWITCH =
  "PRODUCT-VALIDATE_LOGISTIC_KILL_SWITCH"
export const SELLER_ADDENDUM = "ORDER-ALLOW_SELLER_TO_PROPOSE_ADDENDUM"
export const PAYMENT_COA_FLOW = "PAYMENT_COA_FLOW"
export const HIDE_CANCEL_ORDER_BUTTON = "ORDER-HIDE_CANCEL_ORDER_BUTTON"
export const NEW_CURATION_FLOW_RELEASE = "PRODUCT-NEW_CURATION_FLOW_RELEASE"
export const ADDENUM_QTY_ZERO = "ORDER-ADDENDUM_QUANTITY_ZERO"
export const ENABLE_MINICOM_FEATURE =
  "BUYER-MINI-COMPETITION-ENABLE_MINICOM_FEATURE"
export const ORDER_TERMINATION = "ORDER-ALLOW_INTERNAL_TO_TERMINATE_ORDER"
export const SHOW_BYPASS_SAKTI_SIMULATION = "PAYMENT-BYPASS_SAKTI_INTEGRATION"
export const ORDER_RATING_REVIEW = "PRODUCT-ORDER_RATING_REVIEW"
export const USE_GOOGLE_MAP_VERSION_2 = "USE_GOOGLE_MAP_VERSION_2"
export const BLACKLIST_CONSUMER_PLATFORM_INTEGRATION =
  "BLACKLIST-BUYER_CONSUMER_PLATFORM_INTEGRATION"
export const VALIDATION_BUTTON_IS_LOCK_PAYMENT =
  "PAYMENT-VALIDATION_IS_LOCK_PAYMENT"
export const MULTISHIPMENT = "MULTISHIPMENT"
export const IMPROVEMENT_SOLD_PRICE = "MARKETPLACE-IMPROVEMENT_SOLD_PRICE"
export const PDP_REMOVE_DEFAULT_LOCATION_VALIDATION =
  "MARKETPLACE-PDP_REMOVE_DEFAULT_LOCATION_VALIDATION"
export const PAYMENT_UPLOAD_TAX_DEDUCTION = "PAYMENT-UPLOAD_TAX_DEDUCTION"
export const PAYMENT_TAX_AMOUNT_RECOMMENDATION =
  "PAYMENT-TAX_AMOUNT_RECOMMENDATION"
export const MARKETPLACE_CART_IMPROVEMENT = "MARKETPLACE-CART_IMPROVEMENT"
export const CHECKOUT_ENABLE_SINGLE_SHIPMENT =
  "MARKETPLACE-CHECKOUT_ENABLE_SINGLE_SHIPMENT"
export const MARKETPLACE_MINI_HEADER_DROPDOWN =
  "MARKETPLACE-MINI_HEADER_DROPDOWN"
export const MARKETPLACE_HIDE_LOCAL_PRODUCT_ON_EMPTY_ADDRESS =
  "MARKETPLACE-HIDE_LOCAL_PRODUCT_ON_EMPTY_ADDRESS"
export const MARKETPLACE_PRODUCT_SHOW_LOWEST_PRICE_IF_HAS_VARIANT =
  "MARKETPLACE-PRODUCT_SHOW_LOWEST_PRICE_IF_HAS_VARIANT"
export const MARKETPLACE_REMOVE_HARDCODED_FILTER_KLPD =
  "MARKETPLACE-REMOVE_HARDCODED_FILTER_KLPD"

export const IS_PREPROD_OR_STAGING =
  IS_STAGING || IS_PREPRODUCTION || IS_DEVELOPMENT
