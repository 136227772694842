import { cx } from "class-variance-authority"
import { useFormLevelAddAddressStore } from "gtp-ui"
import { type TTempAddress } from "gtp-ui/src/address/modal-add-address/type"
import dynamic from "next/dynamic"
import { Suspense, useContext, useMemo, useState } from "react"
import { MapPin, ChevronDown, X } from "react-feather"
import { Modal, useSnackbar } from "shared-ui"

import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner"

import AddAddressButton from "./AddAddressButton"
import {
  REMOVE_REGION_FORM_ADDRESS,
  USE_GOOGLE_MAP_VERSION_2,
} from "@/commons/constants/flags"
import { FeatureFlagsContext } from "@/contexts/FeatureFlags"
import useAddressSettings from "@/hooks/address/useAddressSettings"
import { useSelectedBuyerAddressStore } from "@/store/useSelectedBuyerAddressStore"

const AddressList = dynamic(
  () => import("gtp-ui/src/address/list/AddressList"),
  {
    ssr: false,
    loading: LoadingSpinner,
  }
)

const ModalAddAddress = dynamic(
  () => import("gtp-ui/src/address/modal-add-address")
)

const DeleteAddressConfirmationModal = dynamic(
  () => import("gtp-ui/src/address/list/DeleteAddressConfirmationModal")
)

const SelectAddress = () => {
  const featureFlag = useContext(FeatureFlagsContext)
  const isShowRegionFormAddAddress =
    !featureFlag?.[REMOVE_REGION_FORM_ADDRESS]?.isEnabled

  const isUseGoogleMapV2 = featureFlag?.[USE_GOOGLE_MAP_VERSION_2]?.isEnabled

  const { enqueueSnackbar } = useSnackbar()
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const [activeModal, setActiveModal] = useState<
    "list" | "form" | "" | "confirm"
  >()

  const {
    formDefaultData,
    addressList,
    editAddressHandler,
    addressSearchValue,
    searchInputChangeHandler,
    deleteAddressHandler,
    confirmDeleteHandler,
    doneFetching,
    loading,
    loadingAdd,
    loadingEdit,
    handleSubmitAddress,
    confirmSetMainAddressHandler,
  } = useAddressSettings()

  const { selectedBuyerAddress, updateSelectedBuyerAddress } =
    useSelectedBuyerAddressStore()
  const formLevel = useFormLevelAddAddressStore((state) => state.level)

  const { mainAddress, addressListSpec } = useMemo(() => {
    let tempMainAddress: TTempAddress | undefined = undefined
    const tempAddressListSpec: TTempAddress[] = []

    addressList.forEach((addressItem) => {
      if (
        addressItem.isMainAddress ||
        addressItem.id === selectedBuyerAddress?.id
      ) {
        tempMainAddress = { ...addressItem, isSelected: true }
      }
      tempAddressListSpec.push({
        ...addressItem,
        isSelected: addressItem.id === selectedBuyerAddress?.id,
      })
    })
    return {
      mainAddress: tempMainAddress,
      addressListSpec: tempAddressListSpec,
    }
  }, [addressList, selectedBuyerAddress])

  const selectedAddress = selectedBuyerAddress ?? mainAddress

  // Uncomment this function to enabled select address by click event

  // const setSnackbar = useSnackbarStore((state) => state.setSnackbar)
  // const selectAddressHandler = (address: TTempAddress) => {
  //   updateSelectedBuyerAddress(address)
  //   setIsAddressModalOpen(false)
  //   setSnackbar({
  //     message: "Anda berhasil mengubah alamat pengiriman",
  //     variant: "success",
  //     hasClose: false,
  //   })
  // }

  const setMainAddress = (addressId: string) => {
    const address = addressList.find((addr) => addr.id === addressId)
    if (selectedAddress) {
      confirmSetMainAddressHandler({ selectedAddress: address })
    }
    updateSelectedBuyerAddress(address)
  }

  const addNewAddressHandler = () => {
    if (addressList.length >= 10) {
      enqueueSnackbar({
        message:
          "Tidak dapat menambahkan alamat, silakan hapus alamat lain jika ingin menambahkan alamat baru.",
        type: "error",
      })
      return
    }
    setIsAddressModalOpen(false)
    setActiveModal("form")
  }

  const editHandler = (idx: number) => {
    editAddressHandler(idx)
    setIsAddressModalOpen(false)
    setActiveModal("form")
  }

  const onFormClosed = () => {
    setIsAddressModalOpen(true)
    setActiveModal("")
  }

  const deleteHandler = (addressId: string) => {
    deleteAddressHandler(addressId)
    setActiveModal("confirm")
  }

  const confirmDelete = () => {
    onFormClosed()
    confirmDeleteHandler()
  }

  return (
    <div className="flex">
      <Modal
        open={isAddressModalOpen}
        modalId="headerNavbarAddressList"
        onOpenChange={() => setIsAddressModalOpen(false)}
        classNames={{ content: "w-2/3" }}
      >
        <div className="flex pb-4">
          <div className="text-body-sm-bold mx-0 my-auto grow">
            Pilih Alamat Pengiriman
          </div>
          <button
            id="header-close-address-modal"
            className="mx-0 my-auto cursor-pointer"
            onClick={() => setIsAddressModalOpen(false)}
          >
            <X size={16} />
          </button>
        </div>
        <div>
          {/* Add props `isClickable` and `customSelectAddressHandler={selectAddressHandler}` to select/change address on click event */}
          {isAddressModalOpen && (
            <AddressList
              addressList={addressListSpec}
              editAddressHandler={editHandler}
              addNewAddressHandler={addNewAddressHandler}
              searchValue={addressSearchValue}
              searchInputChangeHandler={searchInputChangeHandler}
              deleteAddressHandler={deleteHandler}
              setMainAddressHandler={setMainAddress}
              doneFetching={doneFetching}
              customAddBtn={<AddAddressButton onClick={addNewAddressHandler} />}
              selectedBuyerAddressId={selectedBuyerAddress?.id || ""}
            />
          )}
        </div>
      </Modal>
      {activeModal === "form" && (
        <Suspense fallback={<></>}>
          <ModalAddAddress
            withFormRegion={isShowRegionFormAddAddress}
            open={true}
            modalId="header-navbar-add-address"
            onClose={onFormClosed}
            initialData={formDefaultData}
            handleSubmitAddress={(address) =>
              handleSubmitAddress(address, () => {
                setActiveModal("list")
                setIsAddressModalOpen(true)
              })
            }
            isUseGoogleMapV2={isUseGoogleMapV2}
            classNames={{
              content: cx(
                "max-h-[90%]",
                formLevel === "googleMaps" ? "w-[662px]" : "w-[528px]"
              ),
            }}
            loading={loadingAdd || loadingEdit}
          />
        </Suspense>
      )}

      {activeModal === "confirm" && (
        <DeleteAddressConfirmationModal
          isActive={true}
          onClose={onFormClosed}
          onDelete={confirmDelete}
        />
      )}

      {selectedAddress && !loading && (
        <div className="cursor-pointer">
          <div
            onClick={() => setIsAddressModalOpen(true)}
            className="mt-1 flex h-12 w-22 cursor-pointer items-center gap-2 sm:w-24"
          >
            <div className="ml-2 flex max-w-[200px] items-center gap-2 text-xs">
              <div>
                <MapPin size={22} />
              </div>
              <div className="whitespace-nowrap text-tertiary500">
                Dikirim ke
              </div>
              <div className="... truncate font-bold !capitalize">
                {`${selectedAddress.district.name.toLocaleLowerCase()}, ${selectedAddress.city.name.toLocaleLowerCase()}`}
              </div>
            </div>
            <div className="w-5">
              <ChevronDown
                size={16}
                className={isAddressModalOpen ? "rotate-180" : ""}
              />
            </div>
          </div>
        </div>
      )}

      {!loading && !selectedAddress && (
        <div
          className="text-caption-sm-semibold ml-2 flex cursor-pointer items-center gap-2 text-secondary500"
          onClick={() => setIsAddressModalOpen(true)}
        >
          <MapPin size={18} />
          <div className="">Tambah Alamat</div>
        </div>
      )}
    </div>
  )
}

export default SelectAddress
